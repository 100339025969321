import { Component, Mixins } from 'vue-property-decorator';
import { ValidationObserver, ValidationProvider } from 'vee-validate';
import template from './Champs.Template.vue';
import ChampsValidator from './Champs.Validator';
import { ApiService } from '@/services/base/ApiService';
import { DataTableHeader } from '@/shared/models';
import { TemplateChamp } from '@/models';
import { GrilleMixin } from '@/shared/mixins/Grille.mixin';
import { Getter, Action } from 'vuex-class';
import { ReferentielStoreMethods, getterKeyReferentiel } from '@/store/modules/referentiel/ReferentielStore';
import { ValeurReferentielle, TypeValeurReferentielle } from '@/shared/models';
import { PagingAndSorting } from '@/shared/models';
import { ChampCriteria } from './models/ChampCriteria.model';
import { AuthStoreMethods } from '@/store/modules/auth/AuthStore';
import { UserProfile } from '@/store/modules/auth/types';



@Component({
    ...template,
    name: 'TypesChamps',
    components: {
        ValidationProvider,
        ValidationObserver,
    },
})
export default class TypesChamps extends Mixins(ChampsValidator, GrilleMixin) {
    public $refs!: {
        form: HTMLFormElement,
    };
    // Liste des headers
    private headers: DataTableHeader[] = [];
    private champs: TemplateChamp[];
    private canEdit: boolean = true;
    private editedChampIndex: number = -1;
    private editedChampItem: TemplateChamp = new TemplateChamp();
    private editedChampDialog: boolean = false;
    // Loader.
    private loading: boolean = true;
    /**
     * Le nombre d'éléments.
     */
    public totalItems: number = 0;
    /**
     * Le critère de recherche de la grille.
     */
    public nomsChampsCriteria: ChampCriteria = new ChampCriteria();

    @Getter(getterKeyReferentiel(TypeValeurReferentielle.TemplateTypeChamp))
    public typesChamps: ValeurReferentielle[];
    @Action(ReferentielStoreMethods.GET_VALEURS_REFERENTIELLES)
    public getValeursReferentielles: (type: TypeValeurReferentielle) => Promise<ValeurReferentielle[]>;
    // Permet se récupérer le profil de l'utilisateur connecté.
    @Getter(AuthStoreMethods.USER_PROFILE)
    private getUserProfile: Promise<UserProfile>;

    // Permet de savoir si c'est un administrateur informatique.
    public estAdministrateurInformatique: boolean = false;
    /**
     * constructor.
     */
    constructor() {
        super();
        this.headers = [];
        this.champs = [];
    }
    /**
     * Hook qui se déclenche lorsque le composant est crée.
     */
    public created() {
        this.initialize();
        this.getUserProfile.then((profile: UserProfile) => {
            if (profile) {
                this.estAdministrateurInformatique = profile.isSuperAdmin;
                this.getValeursReferentielles(TypeValeurReferentielle.TemplateTypeChamp);
            }
        });   
        this.getData();
    }

    /**
     * Hook qui se déclenche lorsque le composant est monté.
     */
    public initialize() {
        this.headers = [
            { text: 'Id', sortable: false, value: 'id' },
            { text: 'Code du champ', value: 'code' },
            { text: 'Libellé du champ', value: 'libelle', sortable: false },
            { text: 'Type du champ', value: 'typeDuChamp' },
            { text: '', value: 'action', sortable: false },
        ];
        this.champs = [];
    }

    /*
     * Récupération des données.
     */
    public getData() {
        this.nomsChampsCriteria.pagingAndSorting = PagingAndSorting.buildFromPaginationObject(this.pagination);
        this.getListeNomsChamps(this.nomsChampsCriteria);
    }
    /*
     * Recherche Nom d'un champ.
     */
    public rechercheNomsChamps() {
        // Réinitialise la pagination avant de lancer la recherche.
        this.nomsChampsCriteria.pagingAndSorting = new PagingAndSorting();
        this.getData();
    }
    /*
     * Récupération liste des noms de champs.
     */
    public getListeNomsChamps(criteria: ChampCriteria): void {

        this.loading = true;
        const fetchAllNomsChampsApi = new ApiService<TemplateChamp>('templateChamp/obtenirTousParCritere');

        fetchAllNomsChampsApi.getWhere(criteria.queryString).then((response) => {
            this.champs = response.data.data;
            this.totalItems = response.data.totalCount;
            this.$set(this.pagination, 'totalItems', this.totalItems);
        }).finally(() => this.loading = false);
    }
    /**
     * Éditer le champ.
     */
    public editItemChamp(item: TemplateChamp): void {
        this.editedChampIndex = this.champs.indexOf(item);
        this.editedChampItem = Object.assign({}, item);
        this.editedChampDialog = true;
    }
    /**
     * Événement qui se déclenche lorsqu'il y' a un changement de type de champ.
     */
    public onChangeTemplateTypeChamp(value: number): void {
        if (this.editedChampItem && value >= 1) {
            const typeChamp = this.typesChamps.find((item) => item.id === value);
            if (typeChamp && typeChamp.id >= 1) {
                this.editedChampItem.templateTypeChampId = typeChamp.id;
                this.editedChampItem.templateTypeChampCode = typeChamp.code;
                this.editedChampItem.templateTypeChampLibelle = typeChamp.libelle;
            }
        }
    }
    /**
     * Fermer la popup Éditer le champ.
     */
    public close(): void {
        this.editedChampDialog = false;
        setTimeout(() => {
            this.editedChampItem = Object.assign({}, new TemplateChamp());
            this.editedChampIndex = -1;
        }, 300);
    }
    /**
     * Sauvegarde les modifications lors de l'édition du champ.
     */
    public save(): void {

        if (this.editedChampItem && this.editedChampItem.id >= 1) {
            this.loading = true;
            const apiChamp = new ApiService<TemplateChamp>('templateChamp/modifier');
            apiChamp.put(this.editedChampItem).then((response) => {
                if (this.editedChampIndex > -1) {
                    Object.assign(this.champs[this.editedChampIndex], this.editedChampItem);
                } else {
                    this.champs.push(this.editedChampItem);
                }
                this.close();
            }).finally(() => this.loading = false);
        }

    }

    private clear(): void {
        this.$refs.form.reset();
        this.loading = false;
    }
}


import { CriteriaBase } from '@/shared/models';

/**
 * Modèle pour les critères de recherche des utilisateurs.
 * */
export class ChampCriteria extends CriteriaBase {

    /**
     * La recherche saisie.
     */
    public recherche: string = '';
}
import { ValidationMixin } from '@/shared/mixins';
import { Component } from 'vue-property-decorator';

@Component({})
export default class ChampsValidator extends ValidationMixin {

    private libelleRules = [
        (v: string | any) => this.required(v) || 'Le champ libellé est obligatoire',
    ];

    private typesChampsRules = [
        (v: string | any) => this.required(v) || 'Le champ Type du champ est obligatoire',
    ];
}
